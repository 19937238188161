/* body {
  background-color: #090809;
}
*/

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

/* Set the background and text color for the entire page to match the initial mood */
body {
  background-color: #090809; /* Dark background */
  color: #e3e3e3; /* Light text */
  font-family: 'Carrois Gothic SC', 'Nanum Gothic', sans-serif; /* Apply your font styles here if needed */
}

/* Additional global styles */
#root {
  height: 100%;
}