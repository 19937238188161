header {
  display: flex;
  justify-content: space-between;
}

body {
  font-family: 'Nanum Gothic', sans-serif;
}

.page-title {
  color: #009acd;
  font-family:'Carrois Gothic SC', sans-serif;
  font-size: 60px;
  margin: 40px;
}

.auth-buttons-div {
  margin: 40px;
  display: flex;
}

.nice-button {
  display: inline-block;
  margin: auto 15px;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #ff9a30; /* Border color */
  color: #e3e3e3; /* Text color */
  background-color: #ff9a30; /* Background color */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

/* Hover state */
.nice-button:hover {
  background-color: #f57e00; /* Darker background color on hover */
  color: #e3e3e3; /* Text color on hover */
  border: 2px solid #f57e00;
}

/* Active state (when clicked) */
.nice-button:active {
  background-color: #8f4a00; /* Even darker background color on click */
  color: #e3e3e3; /* Text color on click */
  border: 2px solid #8f4a00;
}

.book-article {
  margin: 40px;
}

.book-article h2 {
  font-size: 30px;
  margin: 30px auto;
}

.book-article p {
  font-size: 20px;
}

.interpage-div {
  margin: auto 33%;
  width: 33%;
  align-content: center;
  font-size: 60px;
}

.form {
  margin: auto 33%;
  width: 33%;
  font-size: 20px;
  display: flex;
  flex-flow: column;
  align-content: center;
}

.form-labels {
  margin: 20px auto 10px;
}

#password-register {
  margin-bottom: 20px;
}

#password-login {
  margin-bottom: 20px;
}